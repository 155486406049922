<template>
  <div style="width:200px">
    <div class="img">
      <el-avatar src="" :size="100">
      </el-avatar>
      <div style="width:100px;">{{this.$session.get('name')}}</div>

    </div>
    <el-menu :default-active="acti" class="el-menu-vertical-demo" @select="handleOpen">

      <el-menu-item index="cindex">
        <i class="el-icon-s-home"></i>
        <span slot="title">首页</span>
      </el-menu-item>

      <el-menu-item index="ccase">
        <i class="el-icon-s-order"></i>
        <span slot="title">所涉案件</span>
      </el-menu-item>

      <el-menu-item index="sheet">
        <i class="el-icon-s-claim"></i>
        <span slot="title">提交工单</span>
      </el-menu-item>

      <el-menu-item index="exit">
        <i class="el-icon-download" style="transform:rotate(90deg);"></i>
        退出账号
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>
export default {
  data () {
    return {
      acti: ''
    }
  },
  methods: {
    handleOpen (key) {
      if (key == 'exit') {
        this.$session.remove('token')
        this.$session.remove('sort')
        this.$session.remove('name')
        this.$session.remove('phone')
        this.$router.push('/login');
      } else {
        this.$router.push('/' + key);
      }
    },

  },
  created () {
    // console.log(this.$route.path.slice(1))
    this.acti = this.$route.path.slice(1)
    if (this.$session.get('sort') != '2') {
      if (this.$session.get('sort') == '0') {
        this.$router.push('/index');
      } else {
        if (this.$session.get('sort') == '1') {
          this.$router.push('/pindex');
        } else {
          this.$router.push('/login')
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo {
  width: 200px;
}
.el-menu-item {
  width: 200px;
}
.img {
  // margin: 0 auto;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 20px;
  display: grid;
  place-items: center;
}
.el-avatar {
  background: url("../assets/qiyeicon.png");
  background-size: cover;
}
</style>