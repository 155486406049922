<template>
  <div class="m">
    <leftnav />
    <div class="body">

      <el-dialog title="新建信息" :visible.sync="FormVisible" :top='topp' :show-close='false' :close-on-click-modal='false'>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
          :label-position="topu">
          <el-form-item label="案件名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="案件类别" prop="classes">
            <el-select v-model="ruleForm.classes" placeholder="请选择">
              <el-option v-for="(item , j) in options2" :key="j" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="案件专家" prop="professor">

            <el-select v-model="ruleForm.professor" multiple placeholder="请选择">
              <el-option v-for="item in options" :key="item.tel" :label="item.name+'  '+'('+item.classes+')'"
                :value="item.name">
              </el-option>
            </el-select>

            <!-- <el-cascader v-model="ruleForm.classes" :options="options" placeholder="请选择案件类别及专家个数">
            </el-cascader> -->

          </el-form-item>

          <el-form-item label="录入时间">
            <el-col :span="11">
              <el-form-item prop="begindate">
                <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.begindate" style="width: 100%;"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="企业计划截止时间">
            <el-col :span="11">
              <el-form-item prop="time">
                <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.time" style="width: 100%;"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="企业名称" prop="cname">
            <el-input v-model="ruleForm.cname"></el-input>
          </el-form-item>
          <el-form-item label="企业信用" prop="credit">
            <el-input v-model="ruleForm.credit"></el-input>
          </el-form-item>
          <el-form-item label="企业地址" prop="caddress">
            <el-input v-model="ruleForm.caddress"></el-input>
          </el-form-item>

          <el-form-item label="法人代表" prop="cperson">
            <el-input v-model="ruleForm.cperson"></el-input>
          </el-form-item>

          <el-form-item label="法人电话" prop="ctel">
            <el-input v-model="ruleForm.ctel" type="phone"></el-input>
          </el-form-item>

          <el-form-item label="企业员工规模" prop="guimo">
            <el-input v-model="ruleForm.guimo"></el-input>
          </el-form-item>

          <el-form-item label="企业上年度纳税情况" prop="nashui">
            <el-input v-model="ruleForm.nashui"></el-input>
          </el-form-item>

          <el-form-item label="企业年均营收" prop="yingshou">
            <el-input v-model="ruleForm.yingshou"></el-input>
          </el-form-item>

          <el-form-item label="企业近五年受行政处罚情况" prop="chufa">
            <el-input v-model="ruleForm.chufa"></el-input>
          </el-form-item>

          <el-form-item label="企业近五年涉犯罪情况" prop="fanzui">
            <el-input v-model="ruleForm.fanzui"></el-input>
          </el-form-item>

          <el-form-item label="案件详情" prop="cword">
            <el-input type="textarea" v-model="ruleForm.cword" :autosize="{ minRows: 2, maxRows: 40}"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm('ruleForm')">取消</el-button>
          </el-form-item>
        </el-form>

      </el-dialog>

      <el-dialog title="修改信息" :visible.sync="FormVisible3" :top='topp' :show-close='false'
        :close-on-click-modal='false'>

        <el-form :model="ruleForm3" :rules="rules" ref="ruleForm3" label-width="150px" class="demo-ruleForm"
          :label-position="topu">

          <el-form-item label="录入时间">
            <el-col :span="11">
              <el-form-item prop="begindate">
                <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm3.begindate" style="width: 100%;"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="企业计划截止时间">
            <el-col :span="11">
              <el-form-item prop="time">
                <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.time" style="width: 100%;"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>

          <el-form-item label="企业名称" prop="cname">
            <el-input v-model="ruleForm3.cname"></el-input>
          </el-form-item>

          <el-form-item label="企业信用" prop="credit">
            <el-input v-model="ruleForm3.credit"></el-input>
          </el-form-item>
          <el-form-item label="企业地址" prop="caddress">
            <el-input v-model="ruleForm3.caddress"></el-input>
          </el-form-item>

          <el-form-item label="法人代表" prop="cperson">
            <el-input v-model="ruleForm3.cperson"></el-input>
          </el-form-item>

          <el-form-item label="原法人电话" prop="ctel">
            <el-input v-model="ruleForm3.ctel" disabled></el-input>
          </el-form-item>

          <el-form-item label="新法人电话（如果需要修改的话）" prop="ctel">
            <el-input v-model="pho"></el-input>
          </el-form-item>

          <el-form-item label="企业员工规模" prop="guimo">
            <el-input v-model="ruleForm3.guimo"></el-input>
          </el-form-item>

          <el-form-item label="企业上年度纳税情况" prop="nashui">
            <el-input v-model="ruleForm3.nashui"></el-input>
          </el-form-item>

          <el-form-item label="企业年均营收" prop="yingshou">
            <el-input v-model="ruleForm3.yingshou"></el-input>
          </el-form-item>

          <el-form-item label="企业近五年受行政处罚情况" prop="chufa">
            <el-input v-model="ruleForm3.chufa"></el-input>
          </el-form-item>

          <el-form-item label="企业近五年涉犯罪情况" prop="fanzui">
            <el-input v-model="ruleForm3.fanzui"></el-input>
          </el-form-item>

          <el-form-item label="案件详情" prop="cword">
            <el-input type="textarea" v-model="ruleForm3.cword" :autosize="{ minRows: 2, maxRows: 40}"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm3('ruleForm3')">提交</el-button>
            <el-button @click="resetForm('ruleForm3')">取消</el-button>
          </el-form-item>
        </el-form>

      </el-dialog>

      <div class="flex">
        <el-button @click="newcase()" style="margin-right:20px">新建</el-button>
        <el-input v-model="search" placeholder="搜索案件" />
      </div>

      <el-table style="width:98%" :data="tableData.filter(data => !search || data.name.includes(search))">

        <el-table-column label="案件名称" prop="name">
        </el-table-column>

        <!-- <el-table-column label="专家所在领域" prop="classes">
        </el-table-column> -->

        <el-table-column label="录入时间" prop="begindate">
        </el-table-column>

        <el-table-column label="案件类别" prop="classes">
        </el-table-column>
        <el-table-column label="涉案企业" prop="cname">
        </el-table-column>
        <el-table-column label="涉案企业法人" prop="cperson">
        </el-table-column>

        <el-table-column type="expand" label="详情">

          <template slot-scope="props">

            <el-form label-position="left" inline class="demo-table-expand">

              <el-steps :active="props.row.cnow">
                <!-- <el-step title="检察院录入"></el-step>
                <el-step title="专家评审中"></el-step>
                <el-step title="专家评审结束"></el-step>
                <el-step title="企业整改中"></el-step>
                <el-step title="专家评分中"></el-step>
                <el-step title="专家评分结束"></el-step>
                <el-step title="结束"></el-step> -->

                <el-step v-if="props.row.cnow==-1" title="评审终止"></el-step>
                <el-step v-if="props.row.cnow!=-1" title="检察院录入"></el-step>
                <el-step v-if="props.row.cnow!=-1" title="企业上传整改计划"></el-step>
                <el-step v-if="props.row.cnow!=-1" title="专家评估整改计划"></el-step>
                <el-step v-if="props.row.cnow!=-1" title="企业整改"></el-step>
                <el-step v-if="props.row.cnow!=-1" title="专家评分"></el-step>
                <el-step v-if="props.row.cnow!=-1" title="检察院审核"></el-step>
                <el-step v-if="props.row.cnow!=-1" title="企业提交合规报告"></el-step>
                <el-step v-if="props.row.cnow!=-1" title="专家评估"></el-step>
                <el-step v-if="props.row.cnow!=-1" title="结束"></el-step>

              </el-steps>

              <el-form-item label="案件名称：">
                <span>{{ props.row.name }}</span>
              </el-form-item>

              <el-form-item label="企业提交截止时间：" v-if="props.row.cnow==1||props.row.cnow==6||props.row.cnow==0">
                <span>{{ props.row.time }}</span>
              </el-form-item>

              <!-- <el-form-item label="专家所在领域：">
                <span>{{ props.row.classes }}</span>
              </el-form-item> -->

              <el-form-item label="评审专家：">
                <span v-for="i in  props.row.professor" :key="i" style="margin-right:10px">{{ i }}</span>
              </el-form-item>

              <el-form-item label="评审得分：" v-if='props.row.cnow>4'>
                <span> {{props.row.cscore}} </span>
              </el-form-item>

              <el-form-item label="涉案企业：">
                <span>{{ props.row.cname }}</span>
              </el-form-item>

              <el-form-item label="企业地址：">
                <span>{{ props.row.caddress }}</span>
              </el-form-item>

              <el-form-item label="企业法人：">
                <span>{{ props.row.cperson }}</span>
              </el-form-item>

              <el-form-item label="企业电话：">
                <span>{{ props.row.ctel }}</span>
              </el-form-item>

              <el-form-item label="企业员工规模：">
                <span>{{ props.row.guimo }}</span>
              </el-form-item>

              <el-form-item label="企业上年度纳税情况：">
                <span>{{ props.row.nashui }}</span>
              </el-form-item>

              <el-form-item label="企业年均营收：">
                <span>{{ props.row.yingshou }}</span>
              </el-form-item>

              <el-form-item label="企业近五年受行政处罚情况：">
                <span>{{ props.row.chufa }}</span>
              </el-form-item>

              <el-form-item label="企业近五年涉犯罪情况：">
                <span>{{ props.row.fanzui }}</span>
              </el-form-item>

            </el-form>
            <div class="wenzi">

              <p>
                案件详情：
              </p>
              <div style="max-width:70vw">
                {{"  "+props.row.cword }}</div>
            </div>

            <el-button v-if='props.row.cnow==0' @click="changecase(props)">修改信息</el-button>
            <el-button v-if='props.row.cnow==0' @click="edit('0',props.row)">编辑检察建议</el-button>
            <el-button v-if='props.row.cnow==0' @click="jia(props.row)">发送给企业</el-button>

            <el-button v-if='props.row.cnow>0' @click="show('0',props.row)">查看检察建议书</el-button>

            <el-button v-if='props.row.cnow>1' @click="show('2',props.row)">查看企业整改计划</el-button>

            <el-dropdown @command="handleCommand" v-if='props.row.cnow>2'>
              <el-button>
                查看专家建议<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item+'@'+props.row.name" v-for="(item,i) in props.row.professor" :key="i"
                  v-show='props.row.cscore[i]!=0'>{{item}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-button v-if='props.row.cnow>3&&props.row.cnow<7' @click="show('3',props.row)">查看企业整改结果</el-button>
            <el-button v-if='props.row.cnow>6' @click="show('3',props.row)">查看企业合规报告</el-button>
            <!-- <el-button v-if='props.row.cnow==5' @click="jia(props.row)">通过</el-button>
            <el-button v-if='props.row.cnow==5' @click="nopass(props.row)">整改不通过</el-button> -->
            <el-date-picker v-if='props.row.cnow==5' type="date" placeholder="选择日期" v-model="dateend"
              style="width: 20%;" value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-button v-if='props.row.cnow==5' @click="jia2(props.row)">
              继续
            </el-button>

            <el-button v-if='props.row.cnow==5' @click="end(props.row)">终止</el-button>

            <el-button v-if='props.row.cnow==8' @click="nopass(props.row)">重新提交</el-button>
            <el-button v-if='props.row.cnow==8' @click="end(props.row)">终止</el-button>

            <el-button v-if='props.row.cnow==8' @click="jia(props.row)">
              通过
            </el-button>

            <el-button v-if='props.row.cnow==0||props.row.cnow==9' @click="deleteRow(props.$index, tableData)">删除
            </el-button>
            <!-- <el-button @click="deleteRow(props.$index, tableData)">删除
            </el-button> -->
            <el-button @click="open(props.row)" v-if='props.row.cnow>0'>上一步</el-button>
            <!-- 
            <el-popconfirm v-if='props.row.cnow>0' confirm-button-text='好的' cancel-button-text='不用了' icon="el-icon-info"
              icon-color="red" title="退回上一步会清除现存上一步记录，是否确认？" :confirm="del_jindu(props.row)">
              <el-button>上一步</el-button>

            </el-popconfirm> -->

          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

</template>

<script>
import leftnav from '../../components/mnav.vue'

export default {
  components: {
    leftnav
  },
  created () {
    this.axios.get("http://121.41.179.109:8000/get_clpro").then(resolve => {
      this.options2 = resolve.data

    });

    let data1 = { 'token': this.$session.get('token'), 'phone': this.$session.get('phone') }

    this.axios.post("http://121.41.179.109:8000/get_professor", data1).then(resolve => {
      this.options = resolve.data
    });

    let data = { 'token': this.$session.get('token'), 'phone': this.$session.get('phone') }

    this.axios.post("http://121.41.179.109:8000/get_case", data).then(resolve => {

      this.tableData = resolve.data

    });



  },

  methods: {


    handleCommand (command) {

      let ss = command.split("@");

      this.$router.push({ name: 'showword', params: { type: ss[0], casename: ss[1] } });
    },

    show (num, data) {

      this.$router.push({ name: 'showword', params: { type: num, casename: data.name } });
    },
    edit (num, data) {


      this.$router.push({ name: 'word', params: { type: num, casename: data.name } });


    },
    topro (data) {
      let dmessage = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(), 'name': data.name
      }
      data.cnow += 1
      this.axios.post("http://121.41.179.109:8000/add_jindu", dmessage).then(resolve => {

        if (resolve.data.state == 'success') {
          this.$message.success('发送成功')
          //发短信给专家
        }

      })
    },
    del_jindu (data) {
      let dmessage = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(), 'name': data.name
      }
      this.axios.post("http://121.41.179.109:8000/backspace", dmessage).then(resolve => {
        console.log(resolve);
        this.axios.get("http://121.41.179.109:8000/get_clpro").then(resolve => {
          this.options2 = resolve.data

        });

        let data1 = { 'token': this.$session.get('token'), 'phone': this.$session.get('phone') }

        this.axios.post("http://121.41.179.109:8000/get_professor", data1).then(resolve => {
          this.options = resolve.data
        });

        let data = { 'token': this.$session.get('token'), 'phone': this.$session.get('phone') }

        this.axios.post("http://121.41.179.109:8000/get_case", data).then(resolve => {

          this.tableData = resolve.data

        });

      })

    },
    open (data) {
      this.$confirm('退回上一步会清除现存上一步记录，是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del_jindu(data)
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },

    nopass (data) {
      //axios
      data.cnow -= 2
      // this.$router.push({ name: 'word', params: { type: num, casename: data.name } });
      let dmessage = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(), 'name': data.name
      }

      this.axios.post("http://121.41.179.109:8000/del_jindu", dmessage).then(resolve => {
      })

      this.axios.post("http://121.41.179.109:8000/del_jindu", dmessage).then(resolve => {
        if (resolve.data.state == 'success') {
          this.$message.success('操作成功');

        }
        if (resolve.data.state == 'failure') {

          this.$message.error(resolve.data.reason);
        }

      })

    },
    jia (data) {
      console.log(data.name)
      data.cnow += 1

      let dmessage = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(), 'name': data.name
      }

      this.axios.post("http://121.41.179.109:8000/add_jindu", dmessage).then(resolve => {

        if (resolve.data.state == 'success') {
          this.$message.success('操作成功');

        }
        if (resolve.data.state == 'failure') {

          this.$message.error(resolve.data.reason);
        }

      })
    },
    jia2 (data) {

      if (this.dateend) {
        console.log(data)
        data.cnow += 1
        let da = {
          'token': this.$session.get('token'),
          'phone': this.$session.get('phone').toString(),
          'name': data.name,
          'cname': data.cname,
          'cperson': data.cperson,
          'cword': data.cword,
          'ctel': data.ctel,
          'begindate': data.begindate,
          'caddress': data.caddress,
          'credit': data.credit,
          'guimo': data.guimo,
          'nashui': data.nashui,
          'yingshou': data.yingshou,
          'chufa': data.chufa,
          'fanzui': data.fanzui,
          'time': this.dateend
        }
        this.axios.post("http://121.41.179.109:8000/alter_case", da).then(resolve => {

          if (resolve.data.state == 'success') {

          }
          if (resolve.data.state == 'failure') {

            this.$message.error(resolve.data.reason);
          }

        })

        let dmessage = {
          'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(), 'name': data.name
        }

        this.axios.post("http://121.41.179.109:8000/add_jindu", dmessage).then(resolve => {

          if (resolve.data.state == 'success') {
            this.$message.success('操作成功');

          }
          if (resolve.data.state == 'failure') {

            this.$message.error(resolve.data.reason);
          }

        })
      } else {
        this.$message.error('请选择截止时间');
      }

    },

    deleteRow (index, rows) {

      this.$confirm('此操作将永久删除该案件信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {


        let data1 = { 'phone': this.$session.get('phone'), 'token': this.$session.get('token'), 'name': rows[index].name }
        this.axios.post("http://121.41.179.109:8000/del_case", data1).then(resolve => {

          if (resolve.data.state == 'success') {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });


            rows.splice(index, 1);

          }
        })



        //axios
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    newcase () {
      this.FormVisible = true
    },
    changecase (id) {


      this.ruleForm3 = JSON.parse(JSON.stringify(id.row))
      this.FormVisible3 = true
    },
    submitForm (formName) {
      // console.log(this.ruleForm)
      let dmessage = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(),
        'name': this.ruleForm.name, 'credit': this.ruleForm.credit, 'classes': this.ruleForm.classes,
        'cname': this.ruleForm.cname, 'cperson': this.ruleForm.cperson, 'cword': this.ruleForm.cword,
        'ctel': this.ruleForm.ctel, 'begindate': this.ruleForm.begindate, 'caddress': this.ruleForm.caddress,
        'guimo': this.ruleForm.guimo, 'professor': this.ruleForm.professor,
        'nashui': this.ruleForm.nashui,
        'yingshou': this.ruleForm.yingshou,
        'chufa': this.ruleForm.chufa,
        'fanzui': this.ruleForm.fanzui,
        'time': this.ruleForm.time
      }
      // console.log(dmessage);




      this.axios.post("http://121.41.179.109:8000/add_case", dmessage).then(resolve => {

        if (resolve.data.status == 'success') {
          this.$message.success('添加成功');
          this.$router.go(0);

        }
        if (resolve.data.status == 'failure') {

          this.$message.error(resolve.data.reason);

        }
      })


    },
    submitForm3 (formName) {

      if (this.pho.length < 1) {
        this.pho = 12345678910
      }

      let dmessage = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(),
        'name': this.ruleForm3.name,
        'cname': this.ruleForm3.cname, 'cperson': this.ruleForm3.cperson,
        'cword': this.ruleForm3.cword, 'ctel': this.ruleForm3.ctel,
        'newctel': this.pho.toString(),
        'begindate': this.ruleForm3.begindate, 'caddress': this.ruleForm3.caddress, 'credit': this.ruleForm3.credit,
        'guimo': this.ruleForm3.guimo,
        'nashui': this.ruleForm3.nashui,
        'yingshou': this.ruleForm3.yingshou,
        'chufa': this.ruleForm3.chufa,
        'fanzui': this.ruleForm3.fanzui,
        'time': this.ruleForm.time
      }

      // console.log(dmessage);



      this.axios.post("http://121.41.179.109:8000/alter_case", dmessage).then(resolve => {

        if (resolve.data.state == 'success') {
          this.$message.success('修改成功');
          this.$router.go(0);
        }
        if (resolve.data.state == 'failure') {

          this.$message.error(resolve.data.reason);
        }
        //   // if (resolve.data.status == 'success') {
        //   //   this.$message.success('添加成功');
        //   //   this.$router.go(0);
        //   // }
        //   // if (resolve.data.status == 'failure') {

        //   //   this.$message.error(resolve.data.reason);
        //   // }
      })
      this.ruleForm3 = {}
      this.FormVisible3 = false
    },
    resetForm (formName) {

      this.ruleForm3 = {}
      this.FormVisible3 = false
      this.ruleForm = {}
      this.FormVisible = false
    },
    end (data) {
      let dmessage = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(), 'name': data.name
      }

      this.axios.post("http://121.41.179.109:8000/anegative", dmessage).then(resolve => {

        if (resolve.data.state == 'success') {
          this.$message.success('操作成功');
          this.$router.go(0);

        }
        if (resolve.data.state == 'failure') {

          this.$message.error(resolve.data.reason);
        }
      })
    },



  },
  data () {
    return {
      pho: '',
      options2: [],
      topp: '2vh',
      topu: 'top',
      dateend: '',
      FormVisible: false,
      FormVisible3: false,
      search: '',
      ruleForm: {

        name: '',  //案件名称
        classes: '',  //案件类别
        cname: '',  //企业名称
        caddress: '',  //企业地址
        cperson: '',  //企业法人代表
        ctel: '',  //企业电话号码
        begindate: '',  //录入时间
        cword: '',  //案件详情
        credit: '',
        guimo: '',
        nashui: '',
        yingshou: '',
        chufa: '',
        fanzui: '',
        time: '',
        professor: ""

      },
      ruleForm3: {

        name: '',  //案件名称
        classes: '',  //案件类别
        cname: '',  //企业名称
        caddress: '',  //企业地址
        cperson: '',  //企业法人代表
        ctel: '',  //企业电话号码
        begindate: '',  //录入时间
        cword: '',  //案件详情
        cnow: 0,
        credit: '',
        guimo: '',
        nashui: '',
        yingshou: '',
        chufa: '',
        fanzui: '',
        time: '',
        professor: ""

      },
      rules: {},
      tableData: [],
      options: []

    }



  }


}
</script>

<style lang="less">
.el-dropdown {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
<style lang="less" scoped>
.m {
  display: flex;
}
.body {
  // margin: 0 auto;
  // margin-top: 100px;
  width: 85%;
  // height: 500px;
  margin-top: 10px;
  padding: 0 10px;
}
.el-button-group {
  margin: 20px 0;
}
.eitem {
  display: flex;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.flex {
  display: flex;
}
.wenzi {
  display: flex;
  height: 100%;
  font-size: 14px;
  width: 70vw;
  margin-top: 5px;
  p {
    font-size: 14px;
    margin: 0;
    margin-right: 12px;
    height: 100px;
    width: 100px;
  }
  div {
    width: 100%;
  }
}
</style>

