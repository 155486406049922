<template>
  <div class="m">
    <leftnav />
    <div class="body">

      <el-table style="width: 100%" :data="tableData.filter(data => !search || data.name.includes(search))">

        <el-table-column label="案件名称" prop="name">
        </el-table-column>

        <el-table-column label="录入时间" prop="begindate">
        </el-table-column>
        <el-table-column label="案件状态">

          <template slot-scope="props">
            {{ state(props.row.cnow)}}
          </template>
        </el-table-column>

        <el-table-column label="提交截止时间" prop="time">
        </el-table-column>

        <el-table-column label="涉事企业" prop="cname">
        </el-table-column>

        <el-table-column label="涉事企业法人" prop="cperson">
        </el-table-column>

        <el-table-column type="expand" label="详情">

          <template slot-scope="props">

            <el-form label-position="left" inline class="demo-table-expand">

              <!-- <el-steps :active="props.row.cnow" finish-status="success" simple style="margin-top: 20px">
                <el-step title="检察院录入"></el-step>
                <el-step title="专家评审"></el-step>
                <el-step title="企业整改"></el-step>
                <el-step title="结束"></el-step>
              </el-steps> -->

              <el-form-item label="案件名称：">
                <span>{{ props.row.name }}</span>
              </el-form-item>
              <!-- 
              <el-form-item label="专家所在领域：">
                <span>{{ props.row.classes }}</span>
              </el-form-item> -->

              <el-form-item label="涉案企业：">
                <span>{{ props.row.cname }}</span>
              </el-form-item>

              <el-form-item label="企业地址：">
                <span>{{ props.row.caddress }}</span>
              </el-form-item>

              <el-form-item label="企业法人：">
                <span>{{ props.row.cperson }}</span>
              </el-form-item>

              <el-form-item label="企业电话：">
                <span>{{ props.row.ctel }}</span>
              </el-form-item>

              <el-form-item label="企业员工规模：">
                <span>{{ props.row.guimo }}</span>
              </el-form-item>

              <el-form-item label="企业上年度纳税情况：">
                <span>{{ props.row.nashui }}</span>
              </el-form-item>

              <el-form-item label="企业年均营收：">
                <span>{{ props.row.yingshou }}</span>
              </el-form-item>

              <el-form-item label="企业近五年受行政处罚情况：">
                <span>{{ props.row.chufa }}</span>
              </el-form-item>

              <el-form-item label="企业近五年涉犯罪情况：">
                <span>{{ props.row.fanzui }}</span>
              </el-form-item>

              <!-- <el-form-item label="评审得分：">
                <span>{{ props.row.cscore }}</span>
              </el-form-item> -->

            </el-form>
            <div class="wenzi">

              <p>
                案件详情：
              </p>
              <div>
                {{"  "+props.row.cword }}</div>
            </div>
            <br>

            <el-button @click="show('0',props.row)" v-show="props.row.cnow>0">查看检察建议书</el-button>

            <el-dropdown @command="handleCommand" v-show="props.row.cnow>2">
              <el-button>
                查看专家建议<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="item+'@'+props.row.name" v-for="(item,i) in props.row.professor" :key="i">
                  {{'专家'+(i+1)}}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-button @click="edit('2',props.row)" v-show="props.row.cnow==1">编辑整改计划</el-button>
            <el-button @click="jia(props.row)" v-show="props.row.cnow==1">发送整改计划</el-button>

            <el-button @click="show('2',props.row)" v-show="props.row.cnow>1">查看整改计划</el-button>
            <el-button @click="show('3',props.row)" v-show="props.row.cnow>3&&props.row.cnow<7">查看整改结果</el-button>
            <el-button v-if='props.row.cnow>6' @click="show('3',props.row)">查看合规报告</el-button>
            <el-button @click="edit('3',props.row)" v-show="props.row.cnow==3">编辑整改结果</el-button>
            <el-button @click="jia(props.row)" v-show="props.row.cnow==3">发送整改结果</el-button>

            <el-button @click="edit('3',props.row)" v-show="props.row.cnow==6">编辑合规报告</el-button>
            <el-button @click="jia(props.row)" v-show="props.row.cnow==6">发送合规报告</el-button>

          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

</template>

<script>
import leftnav from '../../components/cnav.vue'


export default {
  components: {
    leftnav
  },
  created () {
    let dmessage = {
      'token': this.$session.get('token'), 'phone': this.$session.get('phone')
    }
    this.axios.post("http://121.41.179.109:8000/cget_case", dmessage).then(resolve => {

      let lisdic = []
      for (let key in resolve.data) {

        if (resolve.data[key].cnow > -2) {
          // lisdic.push(resolve.data[key])

          lisdic.push(resolve.data[key])
        }
      }
      this.tableData = lisdic

    })
  },

  methods: {
    state (data) {
      if (data == -1) {
        return '案件终止'
      } else {
        return '正常'
      }
    },
    handleCommand (command) {

      let ss = command.split("@");

      this.$router.push({ name: 'showword', params: { type: ss[0], casename: ss[1] } });
    },
    jia (data) {
      let dmessage = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(), 'name': data.name
      }

      this.axios.post("http://121.41.179.109:8000/time", dmessage).then(resolve => {
        if (resolve.data.state == 'success') {



          let dmessage = {
            'token': this.$session.get('token'), 'phone': this.$session.get('phone').toString(), 'name': data.name
          }
          data.cnow += 1
          this.axios.post("http://121.41.179.109:8000/add_jindu", dmessage).then(resolve => {

            if (resolve.data.state == 'success') {
              this.$message.success('发送成功')

            }

          })
          //发短信给专家
        } else {
          this.$message.error('超时')
        }
      })


    },

    show (num, data) {

      this.$router.push({ name: 'showword', params: { type: num, casename: data.name } });
    },
    edit (num, data) {
      // let routeUrl = this.$router.resolve({ name: 'word', query: { type: num } });
      // window.open(routeUrl.href, '_blank');
      this.$router.push({ name: 'word', params: { type: num, casename: data.name } });


    },


  },
  data () {
    return {

      topp: '2vh',
      FormVisible: false,
      search: '',
      ruleForm: {
        id: '',
        name: '',  //案件名称
        classes: '',  //案件类别
        cname: '',  //企业名称
        caddress: '',  //企业地址
        cperson: '',  //企业法人代表
        ctel: '',  //企业电话号码
        begindate: '',  //录入时间
        cword: '',  //案件详情
      },
      rules: {},
      tableData: [{
        name: '测试',  //案件名称
        classes: '',  //案件类别
        cname: '朱元璋',  //企业名称
        caddress: '朱元璋',  //企业地址
        cperson: '朱元璋',  //企业法人代表
        ctel: '',  //企业电话号码
        begindate: '',  //录入时间
        cword: '朱元璋',  //案件详情
        cnow: 0,
        credit: '朱元璋',
        guimo: '朱元璋',
        nashui: '朱元璋',
        yingshou: '朱元璋',
        chufa: '朱元璋',
        fanzui: '朱元璋'
      }],

    }
  }


}
</script>
<style lang="less" scoped>
.m {
  display: flex;
}
.body {
  margin: 0 auto;
  // margin-top: 100px;
  width: 100%;
  // height: 500px;
  margin-top: 10px;
  padding: 0 10px;
}
.el-button-group {
  margin: 20px 0;
}
.eitem {
  display: flex;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.flex {
  display: flex;
}
.wenzi {
  display: flex;
  height: 100%;
  font-size: 14px;
  margin-top: 5px;
  p {
    font-size: 14px;
    margin: 0;
    margin-right: 12px;
    height: 100px;
    width: 100px;
  }
  div {
    width: 100%;
  }
}
</style>

