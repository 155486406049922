<template>
  <div class="back">
    <div class='login'>
      <login />
      <div class="companynamelogin">昆吾科技</div>
    </div>

    <div class="beian">
      <a href="http://beian.miit.gov.cn/" target="blank">备案号：鲁ICP备2021044779号</a>
    </div>
  </div>

</template>

<script>
import login from '../components/login.vue'

export default {
  created () {
    this.$notify({
      title: '更新公告',
      dangerouslyUseHTMLString: true,
      duration: 0,
      message: '<h3>本次更新内容：</h3><p>①修复了一些已知bug</p><p style="color:red">②加入工单系统，若在操作过程中出现问题，您可以提交工单来寻求帮助。</p> '
    });
  },
  name: 'Home',
  components: {
    login
  },
  // created () {
  //   let uploadlist = { username: 1, password: 2 }
  //   this.axios.post("http://192.168.43.119:8000/post/login", uploadlist).then(resolve => console.log(resolve));
  // },

}
</script>

<style lang="less" scoped>
.beian {
  position: absolute;

  bottom: 10px;
}
.login {
  // margin: 0 auto;
  //margin-top: 200px;
  //display: grid;
  //place-items: center;
  width: 400px;
  margin-left: 705px;
  margin-top: 200px;
}
.back {
  //background:url("https://s3.bmp.ovh/imgs/2021/11/201eb6cd2e585c45.jpg");
  background: url("../assets/login.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}
.companynamelogin {
  font: 1.8em sans-serif;
  font-weight: 800;
  color: #555;
  position: absolute;
  right: 50px;
  bottom: 50px;
}
</style>