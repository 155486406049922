<template>
  <div class="showpdf1">
    <div class="showword">
      <el-button @click="fanhui">返回</el-button>
      <el-card class="asd">
        <!-- <div v-html="ht">

        </div> -->

        <quill-editor v-model="ht" ref="myQuillEditor">
        </quill-editor>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      ht: ''

    }
  },

  created () {



    let dmessage = {
      'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
      'name': this.$route.params.casename, 'type': this.$route.params.type
    }
    console.log(dmessage)


    this.axios.post("http://121.41.179.109:8000/get_content", dmessage).then(resolve => {

      this.ht = resolve.data.content
      console.log(this.ht);
    });


  },
  methods: {

    fanhui () {
      this.$router.go(-1)
    },
  }


}
</script>



<style lang="less" scoped>
.asd {
  height: 100vh;
}
.showword {
  width: 80%;
  height: 80vh;
  margin: 0 auto;
  margin-top: 50px;
}
.showpdf1 {
  background: url("../assets/1212.jpg");
  background-size: cover;
  width: 100%;
  height: 99vh;
}
</style>