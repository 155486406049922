<template>
  <div class="m">
    <leftnav />
    <div class="body">
      <img class="imgbgm">
      <div class="companyname">昆吾科技</div>
    </div>
  </div>

</template>

<script>
import leftnav from '../../components/mnav.vue'

export default {
  components: {
    leftnav
  },

}
</script>

<style lang="less" scoped>
.m {
  display: flex;
}
.body {
  background: url("../../assets/检察院端口首页01.jpg");
  width: 90%;
  height: 90vh;
  background-size: cover;
}
</style>

<style lang="less">
.companyname {
  font: 1.8em sans-serif;
  font-weight: 800;
  color: #555;
  position: absolute;
  right: 50px;
  bottom: 50px;
}
</style>