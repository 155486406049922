<template>
  <div class="m">
    <leftnav />
    <div class="body">
    </div>
  </div>

</template>

<script>
import leftnav from '../../components/cnav.vue'

export default {
  components: {
    leftnav
  },
}
</script>

<style>
</style>