import { render, staticRenderFns } from "./mc.vue?vue&type=template&id=5f2348f0&scoped=true&"
import script from "./mc.vue?vue&type=script&lang=js&"
export * from "./mc.vue?vue&type=script&lang=js&"
import style0 from "./mc.vue?vue&type=style&index=0&lang=less&"
import style1 from "./mc.vue?vue&type=style&index=1&id=5f2348f0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2348f0",
  null
  
)

export default component.exports