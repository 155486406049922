<template>
  <div class="m">
    <leftnav />
    <div class="body">
      <div class="companyname">昆吾科技</div>
    </div>
  </div>

</template>

<script>
import leftnav from '../../components/pnav.vue'

export default {
  components: {
    leftnav
  },
}
</script>

<style lang="less" scoped>
.m {
  display: flex;
}
.body {
  background: url("../../assets/专家端首页01.jpg");
  width: 90%;
  height: 90vh;
  background-size: cover;
}
</style>