<template>
  <div class="m">
    <leftnav />
    <div class="body">
      <el-dialog title="评估整改计划" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="是否通过" :label-width="formLabelWidth">
            <el-radio v-model="form.pass" label="1">通过</el-radio>
            <el-radio v-model="form.pass" label="2">不通过</el-radio>
          </el-form-item>
          <el-form-item label="建议" :label-width="formLabelWidth">
            <el-input type="textarea" autosize placeholder="请输入内容" v-model="form.word"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="save()">保 存</el-button>
        </div>
      </el-dialog>

      <el-dialog title="评估合规报告" :visible.sync="dialogFormVisible2">
        <el-form :model="form2">
          <el-form-item label="是否通过考验期" :label-width="formLabelWidth">
            <el-radio v-model="form2.pass" label="1">通过</el-radio>
            <el-radio v-model="form2.pass" label="2">不通过</el-radio>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="save2()">保 存</el-button>
        </div>
      </el-dialog>

      <div class="flex">
        <el-input v-model="search" placeholder="搜索案件" />
      </div>

      <el-table style="width: 100%" :data="tableData.filter(data => !search || data.name.includes(search))"
        :row-class-name="tableRowClassName">

        <el-table-column label="案件名称" prop="name">
        </el-table-column>

        <!-- <el-table-column label="专家所在领域" prop="classes">
        </el-table-column> -->

        <el-table-column label="录入时间" prop="begindate">
        </el-table-column>

        <el-table-column label="涉事企业" prop="cname">
        </el-table-column>

        <el-table-column label="企业信用" prop="credit">
        </el-table-column>

        <el-table-column label="涉事企业法人" prop="cperson">
        </el-table-column>

        <el-table-column type="expand" label="详情">

          <template slot-scope="props">

            <el-form label-position="left" inline class="demo-table-expand">

              <el-form-item label="案件名称：">
                <span>{{ props.row.name }}</span>
              </el-form-item>
              <!-- 
              <el-form-item label="专家所在领域：">
                <span>{{ props.row.classes }}</span>
              </el-form-item> -->

              <el-form-item label="涉案企业：">
                <span>{{ props.row.cname }}</span>
              </el-form-item>

              <el-form-item label="企业地址：">
                <span>{{ props.row.caddress }}</span>
              </el-form-item>

              <el-form-item label="企业法人：">
                <span>{{ props.row.cperson }}</span>
              </el-form-item>

              <el-form-item label="企业员工规模：">
                <span>{{ props.row.guimo }}</span>
              </el-form-item>

              <el-form-item label="企业上年度纳税情况：">
                <span>{{ props.row.nashui }}</span>
              </el-form-item>

              <el-form-item label="企业年均营收：">
                <span>{{ props.row.yingshou }}</span>
              </el-form-item>

              <el-form-item label="企业近五年受行政处罚情况：">
                <span>{{ props.row.chufa }}</span>
              </el-form-item>

              <el-form-item label="企业近五年涉犯罪情况：">
                <span>{{ props.row.fanzui }}</span>
              </el-form-item>

            </el-form>
            <div class="wenzi">

              <p>
                案件详情：
              </p>
              <div>
                {{"  "+props.row.cword }}</div>
            </div>

            <el-button-group>
              <!-- <el-button @click="edit(props.row)" v-if="props.row.cnow==2||props.row.cnow==5">编辑评估意见</el-button> -->

              <el-button v-if='props.row.cnow>0' @click="show('0',props.row)">查看检察建议书</el-button>
              <el-button v-if='props.row.cnow>1' @click="show('2',props.row)">查看企业整改计划</el-button>
              <el-button @click="show('3',props.row)" v-show="props.row.cnow>3&&props.row.cnow<7">查看企业整改结果</el-button>
              <el-button v-if='props.row.cnow>6' @click="show('3',props.row)">查看企业合规报告</el-button>
              <el-button @click="clicl2(props.row)" v-show="props.row.cnow==7">评估合规报告</el-button>
              <el-button @click="edit(props.row)" v-if="props.row.cnow==4">评分</el-button>
              <el-button @click="click(props.row)" v-if="props.row.cnow==2">评估整改计划</el-button>
            </el-button-group>

          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

</template>

<script>
import leftnav from '../../components/pnav.vue'


export default {
  components: {
    leftnav
  },
  created () {
    let dmessage = {
      'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
    }
    this.axios.post("http://121.41.179.109:8000/pget_case", dmessage).then(resolve => {
      let lisdic = []
      for (let key in resolve.data) {

        if (resolve.data[key].cnow > -2) {
          // lisdic.push(resolve.data[key])

          lisdic.push(resolve.data[key])
        }
      }
      this.tableData = lisdic

    })

  },

  methods: {
    click (data) {
      this.dialogFormVisible = true
      this.form.name = data.name
    },
    clicl2 (data) {
      this.dialogFormVisible2 = true
      this.form2.name = data.name
    },

    tableRowClassName: function (row, index) {
      if (row.row.cnow == 1) {
        return 'hidden-row';
      }
      return '';
    },

    edit (data) {

      this.$router.push({ name: 'pword', params: { type: this.$session.get('name'), case: data } });


    },
    save2 () {
      if (this.form2.pass) {
        this.dialogFormVisible2 = false

        let daty = {
          'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
          'name': this.form2.name, 'res': Number(this.form2.pass)
        }
        console.log(daty)
        this.axios.post("http://121.41.179.109:8000/yesorno", daty).then(resolve => {
          if (resolve.data.state == "success") {
            this.$message.success('保存成功');
          } else {
            this.$message.error(resolve.data.reason);
          }

        })


      } else {
        this.$message.error('请选择是否通过');
      }

    },
    save () {
      if (this.form.pass) {
        this.dialogFormVisible = false

        let daty = {
          'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
          'name': this.form.name, 'res': Number(this.form.pass)
        }
        console.log(daty)
        this.axios.post("http://121.41.179.109:8000/yesorno", daty).then(resolve => {
          if (resolve.data.state == "success") {
            this.$message.success('保存成功');
          } else {
            this.$message.error(resolve.data.reason);
          }
        })

        let dmessage = {
          'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
          'name': this.form.name, 'content': this.form.word
        }


        this.axios.post("http://121.41.179.109:8000/alter_content", dmessage).then(resolve => {
          console.log(resolve)
        })
      } else {
        this.$message.error('请选择是否通过');
      }

    },


    show (num, data) {

      this.$router.push({ name: 'showword', params: { type: num, casename: data.name } });
    },

  },
  data () {
    return {
      form: {
        pass: '',
        word: '',
        name: ''
      },
      form2: {
        pass: '',
        name: ''
      },
      formLabelWidth: '120px',
      dialogFormVisible: false,
      dialogFormVisible2: false,
      topp: '2vh',
      FormVisible: false,
      search: '',
      ruleForm: {
        id: '',
        name: '',  //案件名称
        classes: '',  //案件类别
        cname: '',  //企业名称
        caddress: '',  //企业地址
        cperson: '',  //企业法人代表
        ctel: '',  //企业电话号码
        begindate: '',  //录入时间
        cword: '',  //案件详情
        credit: ''
      },
      rules: {},
      tableData: [],

    }
  }


}
</script>
<style lang="less" scoped>
.el-table .hidden-row {
  display: none;
}
.m {
  display: flex;
}
.body {
  margin: 0 auto;
  // margin-top: 100px;
  width: 100%;
  // height: 500px;
  margin-top: 10px;
  padding: 0 10px;
}
.el-button-group {
  margin: 20px 0;
}
.eitem {
  display: flex;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.flex {
  display: flex;
}
.wenzi {
  display: flex;
  height: 100%;
  font-size: 14px;
  margin-top: 5px;
  p {
    font-size: 14px;
    margin: 0;
    margin-right: 12px;
    height: 100px;
    width: 100px;
  }
  div {
    width: 100%;
  }
}
</style>

