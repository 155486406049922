<template>

  <el-form :model="ruleForm" status-icon ref="ruleForm" label-width="80px" class="demo-ruleForm">

    <el-form-item label="手机号" prop="username" :rules="[
      { required: true, message: '手机号不能为空'},
      { type: 'number', message: '手机号必须为数字值'}
    ]">
      <el-input v-model.number="ruleForm.username"></el-input>
    </el-form-item>
    <el-form-item label="验证码" prop="pass" :rules="[
      { required: true, message: '验证码不能为空'},
     
    ]">

      <div style="display:flex">
        <el-input type="num" v-model="ruleForm.pass"></el-input>
        <el-button @click.prevent="sendpass()">发送验证码</el-button>
      </div>
    </el-form-item>

    <el-form-item>
      <el-button style="width:110px;float:right" type="primary" @click="submitForm('ruleForm')">登录</el-button>
    </el-form-item>
  </el-form>

</template>
<script>
export default {
  data () {
    var checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'));
      } else {
        callback();
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('验证码'));
      } else {
        if (value.length < 6) {
          callback(new Error('密码至少大于6位'));
        } else {
          callback();
        }

      }
    };

    return {
      ruleForm: {
        pass: '',
        username: ''
      },
      rules: {

        username: [
          { validator: checkUsername, trigger: 'blur', required: true, }
        ]
      }
    };
  },
  methods: {
    sendpass () {

      if (this.ruleForm.username && Number.isInteger(this.ruleForm.username)) {
        let phonedata = { phone: this.ruleForm.username }
        this.axios.post("http://121.41.179.109:8000/sendmsg", phonedata).then(resolve => {
          console.log(resolve)
          if (resolve.data.status == 'success') {
            this.$message.success('发送成功');
          }
          if (resolve.data.status == 'failure') {
            if ('isv.BUSINESS_LIMIT_CONTROL') {
              this.$message.error('操作频繁，请稍后再试！');
            } else { this.$message.error(resolve.data.reason); }

          }
        })
      }

    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          let logindata = { phone: this.ruleForm.username, password: this.ruleForm.pass }

          this.axios.post("http://121.41.179.109:8000/login", logindata).then(resolve => {
            console.log(resolve)


            if (resolve.data.status == 'success') {

              this.$session.set('token', resolve.data.token)
              this.$session.set('sort', resolve.data.sort)
              this.$session.set('name', resolve.data.name)
              this.$session.set('phone', this.ruleForm.username)
              this.$message.success('登录成功');
              this.$refs[formName].resetFields();
              if (resolve.data.sort == '0') {
                this.$router.push('/index');
              }
              if (resolve.data.sort == '1') {
                this.$router.push('/pindex')
              }
              if (resolve.data.sort == '2') {
                this.$router.push('/cindex')
              }


            } else {
              this.$message.error(resolve.data.reason);
            }

          });
        } else {
          return false;
        }
      });
    },

  }
}
</script>