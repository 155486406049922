import { render, staticRenderFns } from "./cindex.vue?vue&type=template&id=63e0c616&scoped=true&"
import script from "./cindex.vue?vue&type=script&lang=js&"
export * from "./cindex.vue?vue&type=script&lang=js&"
import style0 from "./cindex.vue?vue&type=style&index=0&id=63e0c616&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e0c616",
  null
  
)

export default component.exports