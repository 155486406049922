<template>
  <div class="m">
    <leftnav />
    <div class="body">

      <div class="formadd">

        <div class="class1">

          删除管理员
          <el-form :model="del" status-icon label-width="100px" class="demo-ruleForm">

            <el-form-item label="手机号">
              <el-input v-model="del.phone"> </el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="del.pass"> </el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm3()">提交</el-button>

            </el-form-item>
          </el-form>
        </div>
        <div class="class1">
          添加管理员
          <el-form :model="form" status-icon label-width="100px" class="demo-ruleForm">

            <el-form-item label="姓名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="form.phone"> </el-input>
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="form.pass"> </el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm()">提交</el-button>

            </el-form-item>
          </el-form>
        </div>
        <div class="class1">
          修改管理密码
          <el-form :model="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="原始密码">
              <el-input type="password" v-model="ruleForm.password"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
              <el-input type="password" v-model="ruleForm.pass"></el-input>
            </el-form-item>
            <el-form-item label="确认新密码">
              <el-input type="password" v-model="ruleForm.checkpass"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm2()">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftnav from '../../components/mnav.vue'

export default {
  components: {
    leftnav
  }, data () {
    return {
      form: {
        name: '',
        pass: '',
        phone: '',
      },
      ruleForm: {
        pass: '',
        checkpass: '',
        password: ''
      },
      del: {
        phone: '',
        pass: ''
      }

    };
  },
  methods: {
    submitForm2 () {

      if (this.ruleForm.pass != this.ruleForm.checkpass) {
        this.$message.error('两次密码不一样');
      } else {
        let data = {
          'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
          'new_password': this.ruleForm.pass, 'old_password': this.ruleForm.password,

        }
        this.axios.post("http://121.41.179.109:8000/change_password", data).then(resolve => {
          if (resolve.data.state == 'success') {
            this.$message.success('修改成功')

          } else {
            this.$message.error(resolve.data.reason);
          }
        })
      }


    },
    submitForm3 () {
      let data = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
        'del_phone': this.del.phone, 'password': this.del.pass,
      }
      this.axios.post("http://121.41.179.109:8000/del_admin", data).then(resolve => {
        if (resolve.data.state == 'success') {
          this.$message.success('删除成功')
        }
      })
    },
    submitForm () {
      let data = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
        'name': this.form.name, 'password': this.form.pass, 'new_phone': this.form.phone,

      }
      this.axios.post("http://121.41.179.109:8000/add_admin", data).then(resolve => {
        if (resolve.data.state == 'success') {
          this.$message.success('添加成功')

        }
      })
    },

  }
}
</script>

<style lang="less" scoped>
.m {
  display: flex;
}
.body {
  margin: 0 auto;
  margin-top: 10px;
  width: 100%;
  height: 90vh;
  // height: 500px;
  display: grid;
  place-items: center;
}
.formadd {
  // width: 500px;
  display: flex;
}
.class1 {
  text-align: center;
}
</style>