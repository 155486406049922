<template>
  <div class="m">
    <leftnav />
    <div class="body">

    </div>
  </div>
</template>

<script>
import leftnav from '../../components/mnav.vue'

export default {
  components: {
    leftnav
  },
}
</script>

<style lang="less" scoped>
.m {
  display: flex;
}
.body {
  margin: 0 auto;
  margin-top: 10px;
  width: 100%;
  // height: 500px;
}
</style>