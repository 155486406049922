<template>
  <div class="m">
    <leftnav />
    <div class="body">
      <div class="btnadmin">
        <el-button @click="zeng=true">
          添加
        </el-button>
        <el-button @click="shan=true">
          删除
        </el-button>
        <el-button @click="gai=true">
          修改密码
        </el-button>
      </div>

      <el-table style="width: 100%" ref="filterTable" :data='tableData'>

        <el-table-column prop="name" label="姓名" sortable>
        </el-table-column>

        <el-table-column prop="phone" label="手机号" sortable>
        </el-table-column>

      </el-table>

    </div>
    <el-dialog :visible.sync="zeng">
      <el-form :model="form" status-icon label-width="100px" class="demo-ruleForm">

        <el-form-item label="姓名">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.phone"> </el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.pass"> </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm()">提交</el-button>

        </el-form-item>
      </el-form>

    </el-dialog>

    <el-dialog :visible.sync="shan">

      <el-form :model="del" status-icon label-width="100px" class="demo-ruleForm">

        <el-form-item label="手机号">
          <el-input v-model="del.phone"> </el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="del.pass"> </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm3()">提交</el-button>

        </el-form-item>
      </el-form>

    </el-dialog>

    <el-dialog :visible.sync="gai">
      <el-form :model="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="原始密码">
          <el-input type="password" v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input type="password" v-model="ruleForm.pass"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码">
          <el-input type="password" v-model="ruleForm.checkpass"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm2()">提交</el-button>
        </el-form-item>
      </el-form>

    </el-dialog>
  </div>

</template>

<script>
import leftnav from '../../components/mnav.vue'


export default {
  components: {
    leftnav
  },
  created () {

    let data = { 'token': this.$session.get('token'), 'phone': this.$session.get('phone') }

    this.axios.post("http://121.41.179.109:8000/get_admin", data).then(resolve => {

      this.tableData = resolve.data
    });
  },

  methods: {


  },
  data () {

    return {
      zeng: false,
      shan: false,
      gai: false,
      topp: '10vh',
      search: '',

      tableData: [],
      form: {
        name: '',
        pass: '',
        phone: '',
      },
      ruleForm: {
        pass: '',
        checkpass: '',
        password: ''
      },
      del: {
        phone: '',
        pass: ''
      }

    }
  },
  methods: {
    submitForm2 () {
      if (this.ruleForm.pass && this.ruleForm.password && this.ruleForm.checkpass) {
        if (this.ruleForm.pass != this.ruleForm.checkpass) {
          this.$message.error('两次密码不一样');
        } else {
          let data = {
            'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
            'new_password': this.ruleForm.pass, 'old_password': this.ruleForm.password,

          }
          this.axios.post("http://121.41.179.109:8000/change_password", data).then(resolve => {
            if (resolve.data.state == 'success') {
              this.$message.success('修改成功')
              this.ruleForm = {}
              this.gai = false
              this.$router.go(0);

            } else {
              this.$message.error(resolve.data.reason);
            }
          })
        }
      } else {
        this.$message.error('请输入所有信息');
      }



    },
    submitForm3 () {
      if (this.del.pass && this.del.phone) {
        let data = {
          'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
          'del_phone': this.del.phone, 'password': this.del.pass,
        }
        this.axios.post("http://121.41.179.109:8000/del_admin", data).then(resolve => {
          if (resolve.data.state == 'success') {
            this.$message.success('删除成功')
            this.del = {}
            this.shan = false
            this.$router.go(0);
          } else {
            this.$message.error(resolve.data.reason);

          }
        })
      } else {
        this.$message.error('请输入所有信息')
      }


    },

    submitForm () {//zenhg
      if (this.form.name && this.form.pass && this.form.phone) {

        let data = {
          'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
          'name': this.form.name, 'password': this.form.pass, 'new_phone': this.form.phone,

        }
        this.axios.post("http://121.41.179.109:8000/add_admin", data).then(resolve => {
          if (resolve.data.state == 'success') {
            this.$message.success('添加成功')

            this.form = {}
            this.zeng = false
            this.$router.go(0);
          } else {
            this.$message.error(resolve.data.reason);

          }
        })
      } else {
        this.$message.error('请输入所有信息')
      }

    },


  }


}
</script>
<style lang="less" scoped>
.m {
  display: flex;
}
.body {
  width: 85%;
  // height: 500px;
  margin-top: 10px;
  padding: 0 10px;
  // height: 500px;
}
.flex {
  display: flex;
}
</style>