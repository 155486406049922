<template>
  <div>

    <div class="content edit_container editbody">
      <div style="display:flex;margin:5px 0;">
        <el-button @click="fanhui()">返回</el-button>
        <el-button @click="saveHtml($event)">提交</el-button>
      </div>

      <el-card class="boxcard">
        <div>
          <p style="font-size:1.2em">
            在使用本系统的过程中如果遇到问题或者需要对已经提交的信息进行修改，可以在此界面提交工单来解决。提交工单后开发者会立即收到请求并会在2小时之内处理问题，处理结果将会以短信形式发送到手机。

          </p>
        </div>
      </el-card>
      <div v-loading="loading">
        <el-input v-model="title" placeholder="工单标题"></el-input>
        <quill-editor v-model="content" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)" @change="onEditorChange($event)">
        </quill-editor>

      </div>

    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      content: ``,
      title: '',
      loading: false,
      centerDialogVisible: false,
      editorOption: {
        theme: 'snow',
        placeholder: "工单内容"
      },
      modules: {
        toolbar: [
          // ['bold', 'italic', 'underline', 'strike'],
          // ['blockquote', 'code-block'],
          // [{ 'header': 1 }, { 'header': 2 }],
          // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          // [{ 'script': 'sub'}, { 'script': 'super' }],
          // [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          // [{ 'color': [] }, { 'background': [] }],
          // [{ 'font': [] }],
          // [{ 'align': [] }],
          // ['clean'],
          // ['image','video']
        ]
      }
    }
  },
  computed: {
    editor () {
      return this.$refs.myQuillEditor.quill
    }
  },
  created () {

  },


  methods: {

    fanhui () {
      this.$router.go(-1)
    },
    onEditorReady (editor) { // 准备编辑器
    },
    onEditorBlur () { }, // 失去焦点事件
    onEditorFocus () { }, // 获得焦点事件
    onEditorChange () { }, // 内容改变事件
    saveHtml (event) {

      if (this.title.length < 1) {
        this.$message({
          message: '请输入标题',
          type: 'error'
        });
        return false
      }

      if (this.content.length < 1) {
        this.$message({
          message: '请输入内容',
          type: 'error'
        });
        return false
      }

      this.loading = true
      let dmessage = {
        'phone': this.$session.get('phone').toString(),
        'token': this.$session.get('token'),
        'content': this.content,
        'title': this.title
      }


      console.log(dmessage)

      this.axios.post("http://121.41.179.109:8000/work_order", dmessage).then(resolve => {
        console.log(resolve)
        if (resolve.data.status == 'success') {
          this.loading = false
          this.title = ""
          this.content = ""
          this.$message({
            message: '提交成功',
            type: 'success'
          });

        }
        // if (resolve.data.state == 'failure') {

        //   this.$message.error(resolve.data.reason);
        // }
      })


    }
  }
}
</script>

<style  scoped>
.boxcard {
  margin: 50px 0;
  /* margin-top: 100px !important; */
}
</style>
