<template>
  <div class="m">
    <leftnav />
    <div class="body">

      <div class="flex">
        <el-button @click="newinf()" style="margin-right:20px">新建</el-button>
        <el-input v-model="search" placeholder="搜索专家" />
      </div>

      <el-dialog title="新建信息" :visible.sync="dialogFormVisible" :top='topp' :show-close='false'
        :before-close="nochange">

        <!-- <el-form :model="form">

          <el-form-item label="姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio-group v-model="form.sex">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="工作单位职位">
            <el-input v-model="form.job"></el-input>
          </el-form-item>

          <el-form-item label="手机号">
            <el-input v-model="form.tel"></el-input>
          </el-form-item>

          <el-form-item label="分类">
            <el-select v-model="form.class" placeholder="请选择活动区域">
              <el-option label="金融财会" value="金融财会"></el-option>
              <el-option label="生态环境" value="生态环境"></el-option>
            </el-select>
          </el-form-item>

        </el-form> -->

        <el-form :model="form" status-icon :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm"
          v-if="dialogFormVisible">

          <el-form-item label="姓名" prop="name">
            <el-input v-model.number="form.name"></el-input>
          </el-form-item>

          <el-form-item label="性别">
            <el-radio-group v-model="form.gender">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="职务" prop="work">
            <el-input v-model.number="form.work"></el-input>
          </el-form-item>

          <el-form-item label="所在领域" prop="classes">
            <el-select v-model="form.classes" placeholder="请选择专家所在领域">
              <el-option label="金融财会" value="金融财会"></el-option>
              <el-option label="生态环境" value="生态环境"></el-option>
              <el-option label="质量安全" value="质量安全"></el-option>
              <el-option label="国资监管" value="国资监管"></el-option>
              <el-option label="律师法务" value="律师法务"></el-option>
              <el-option label="文化教育" value="文化教育"></el-option>
              <el-option label="医疗卫生" value="医疗卫生"></el-option>
              <el-option label="工业信息" value="工业信息"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="手机号" prop="tel">
            <el-input v-model.number="form.tel"></el-input>
          </el-form-item>

        </el-form>

        <div slot="footer" class="dialog-footer">

          <el-button @click="nochange('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="change('ruleForm')">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="修改信息" :visible.sync="dialogFormVisible2" :top='topp' :show-close='false'
        :before-close="nochange">

        <el-form :model="form2" status-icon :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm"
          v-if="dialogFormVisible2">

          <el-form-item label="姓名" prop="name">
            <el-input v-model.number="form2.name"></el-input>
          </el-form-item>

          <el-form-item label="性别">
            <el-radio-group v-model="form2.gender">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="职务" prop="work">
            <el-input v-model.number="form2.work"></el-input>
          </el-form-item>

          <el-form-item label="领域" prop="classes">
            <el-select v-model="form2.classes" placeholder="请选择专家所在领域">
              <el-option label="金融财会" value="金融财会"></el-option>
              <el-option label="生态环境" value="生态环境"></el-option>
              <el-option label="质量安全" value="质量安全"></el-option>
              <el-option label="国资监管" value="国资监管"></el-option>
              <el-option label="律师法务" value="律师法务"></el-option>
              <el-option label="文化教育" value="文化教育"></el-option>
              <el-option label="医疗卫生" value="医疗卫生"></el-option>
              <el-option label="工业信息" value="工业信息"></el-option>
            </el-select>
          </el-form-item>

        </el-form>

        <div slot="footer" class="dialog-footer">

          <el-button @click="nochange('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="change2('ruleForm')">确 定</el-button>
        </div>
      </el-dialog>

      <el-table style="width: 95%" ref="filterTable"
        :data="tableData.filter(data => !search || data.name.includes(search))">

        <el-table-column prop="name" label="姓名" sortable>
        </el-table-column>
        <!-- 
        <el-table-column prop="gender" label="性别" :filters="[{ text: '男', value: '男' }, { text: '女', value: '女' }]"
          :filter-method="filterSex" filter-placement="bottom-end">
        </el-table-column> -->

        <el-table-column prop="gender" label="性别" sortable>
        </el-table-column>

        <el-table-column prop="work" label="职务">
        </el-table-column>

        <el-table-column prop="tel" label="手机号" sortable>
        </el-table-column>

        <el-table-column prop="classes" label="领域" sortable>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">

            <el-button @click="upRow(scope.$index)" type="text" size="small">
              修改
            </el-button>
            <el-button @click.native.prevent="deleteRow(scope.$index, tableData)" type="text" size="small">
              移除
            </el-button>
          </template>
        </el-table-column>

      </el-table>

    </div>
  </div>

</template>

<script>
import leftnav from '../../components/mnav.vue'


export default {
  components: {
    leftnav
  },
  created () {

    let data = { 'token': this.$session.get('token'), 'phone': this.$session.get('phone') }

    this.axios.post("http://121.41.179.109:8000/get_professor", data).then(resolve => {

      this.tableData = resolve.data
    });
  },

  methods: {
    deleteRow (index, rows) {

      this.$confirm('此操作将永久删除该专家信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data1 = { 'phone': this.$session.get('phone'), 'token': this.$session.get('token'), 'tel': rows[index].tel }
        this.axios.post("http://121.41.179.109:8000/del_professor", data1).then(resolve => {

        })

        this.$message({
          type: 'success',
          message: '删除成功!'
        });


        rows.splice(index, 1);

        //axios
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    upRow (i) {

      this.form2 = JSON.parse(JSON.stringify(this.tableData[i]))

      this.dialogFormVisible2 = true
    },
    newinf () {
      this.dialogFormVisible = true
    },
    change2 () {



      if (!this.form2.name) {
        this.$message.error('请输入姓名');
        return false
      }
      if (!this.form2.classes) {
        this.$message.error('请输入专家所在领域');
        return false
      }
      let data = { 'name': this.form2.name, 'phone': this.$session.get('phone'), 'token': this.$session.get('token'), 'gender': this.form2.gender, 'work': this.form2.work, 'classes': this.form2.classes, 'tel': this.form2.tel }



      this.axios.post("http://121.41.179.109:8000/alter_professor", data).then(resolve => {

        if (resolve.data.status == 'success') {
          this.$message.success('修改成功');
          this.$router.go(0);
        }
        if (resolve.data.status == 'failure') {

          this.$message.error(resolve.data.reason);
        }

      })

      this.dialogFormVisible2 = false
      this.form2 = {}






    },
    change () {

      if (!this.form.tel) {
        this.$message.error('请输入手机号');
        return false
      }

      if (!this.form.name) {
        this.$message.error('请输入姓名');
        return false
      }
      if (!this.form.classes) {
        this.$message.error('请输入专家所在领域');
        return false
      }

      let data = { 'name': this.form.name, 'phone': this.$session.get('phone'), 'token': this.$session.get('token'), 'gender': this.form.gender, 'work': this.form.work, 'classes': this.form.classes, 'tel': this.form.tel }

      this.axios.post("http://121.41.179.109:8000/add_professor", data).then(resolve => {

        if (resolve.data.status == 'success') {
          this.tableData.push(data)


          this.$message.success('注册成功');
          this.dialogFormVisible = false
          this.form = {}
        }
        if (resolve.data.status == 'failure') {

          this.$message.error(resolve.data.reason);
        }
      })








    },
    nochange () {
      this.dialogFormVisible = false
      this.form = {}

      this.dialogFormVisible2 = false
      this.form2 = {}
    },
    filterTag (value, row) {

      return row.class === value;
    },
    filterSex (value, row) {

      return row.sex === value;
    },


  },
  data () {

    return {
      dialogFormVisible2: false,
      dialogFormVisible: false,
      topp: '10vh',
      search: '',
      form: {
        name: '',
        classes: '',
        tel: '',
        gender: '',
        work: ''
      },
      form2: {
        name: '',
        classes: '',
        tel: '',
        gender: '',
        work: ''
      },
      tableData: [],
      rules: {

        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        class: [
          { required: true, message: '请选择专家所在领域', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ]
      }
    }
  }


}
</script>
<style lang="less" scoped>
.m {
  display: flex;
}
.body {
  width: 85%;
  // height: 500px;
  margin-top: 10px;
  padding: 0 10px;
  // height: 500px;
}
.flex {
  display: flex;
}
</style>