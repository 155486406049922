import { render, staticRenderFns } from "./pindex.vue?vue&type=template&id=e5bf433c&scoped=true&"
import script from "./pindex.vue?vue&type=script&lang=js&"
export * from "./pindex.vue?vue&type=script&lang=js&"
import style0 from "./pindex.vue?vue&type=style&index=0&id=e5bf433c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5bf433c",
  null
  
)

export default component.exports