import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Mindex from '../views/检察院端/mindex.vue'
import Mp from '../views/检察院端/mp.vue'
import Mc from '../views/检察院端/mc.vue'
import Me from '../views/检察院端/me.vue'
import Ma from '../views/检察院端/ma.vue'
import Ms from '../views/检察院端/ms.vue'
import Word from '../views/word.vue'
import Cindex from '../views/企业端/cindex.vue'
import Ccase from '../views/企业端/ccase.vue'
import Ce from '../views/企业端/ce.vue'
import Showword from '../views/showpdf.vue'
import Pindex from '../views/专家端/pindex.vue'
import Pcase from '../views/专家端/pcase.vue'
import Pword from '../views/专家端/pword.vue'
import Sheet from '../views/sheet.vue'
import notFound from '../views/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: Login
  },
  {
    path: '/sheet',
    component: Sheet
  },

  {
    path: '/cindex',
    component: Cindex
  },
  
  {
    path: '/ccase',
    component: Ccase
  },
  
  {
    path: '/cindex',
    component: Cindex
  },
  {
    path: '/cmsg',
    component: Ce
  },
  {
    path: '/pcase',
    component: Pcase
  },
  {
    path: '/pindex',
    component: Pindex
  },
  {
    path: '/pword',
    name:'pword',
    component: Pword
  },
  {
    path: '/',
    component: Mindex
  },
  {
    path: '/index',
    component: Mindex
  },
  {
    path: '/case',
    component: Mc
  },
  {
    path: '/add',
    component: Ma
  },
  {
    path: '/see',
    component: Ms
  },
  {
    path: '/professor',
    component: Mp
  },
  {
    path: '/msg',
    component: Me
  },
  {
    path: '/word',
    name:'word',
    component: Word
  },
  {
    path: '/showword',
    name:'showword',
    component: Showword
  },
  {
    path: '*',
    component:notFound
  }
  
]

const router = new VueRouter({
  routes
})

export default router
