<template>
  <div class="wordbg">
    <!-- <div style="display:grid;place-items: center;">
      <div class="editbtn">
        <el-button @click="fanhui()">fanhui</el-button>
        <el-button @click="saveHtml($event)">保存</el-button>
      </div>
    </div> -->
    <div class="content edit_container editbody">
      <div style="display:flex;margin:5px 0;">
        <el-button @click="fanhui()">返回</el-button>
        <el-button @click="saveHtml($event)">保存</el-button>
      </div>
      <quill-editor v-model="content" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)" @change="onEditorChange($event)">
      </quill-editor>
    </div>

  </div>
</template>

<script>


export default {
  data () {
    return {
      content: ``,
      editorOption: {
        theme: 'snow',
        placeholder: "请在此输入..."
      },
      modules: {
        toolbar: [
          // ['bold', 'italic', 'underline', 'strike'],
          // ['blockquote', 'code-block'],
          // [{ 'header': 1 }, { 'header': 2 }],
          // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          // [{ 'script': 'sub'}, { 'script': 'super' }],
          // [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          // [{ 'color': [] }, { 'background': [] }],
          // [{ 'font': [] }],
          // [{ 'align': [] }],
          // ['clean'],
          // ['image','video']
        ]
      }
    }
  },
  computed: {
    editor () {
      return this.$refs.myQuillEditor.quill
    }
  },
  created () {


    let dmessage = { 'token': this.$session.get('token'), 'phone': this.$session.get('phone'), 'type': this.$route.params.type, 'name': this.$route.params.casename }
    console.log(dmessage)
    this.axios.post("http://121.41.179.109:8000/get_content", dmessage).then(resolve => {
      console.log(resolve, '45')
      this.content = resolve.data.content
    })


  },


  methods: {

    fanhui () {
      this.$router.go(-1)
    },
    onEditorReady (editor) { // 准备编辑器
    },
    onEditorBlur () { }, // 失去焦点事件
    onEditorFocus () { }, // 获得焦点事件
    onEditorChange () { }, // 内容改变事件
    saveHtml (event) {
      let dmessage = {
        'token': this.$session.get('token'), 'phone': this.$session.get('phone'),
        'name': this.$route.params.casename, 'content': this.content
      }
      if (this.$route.params.type == 2 || this.$route.params.type == 3) {
        dmessage.phone = this.$session.get('phone') + this.$route.params.type
      }



      console.log(dmessage)

      this.axios.post("http://121.41.179.109:8000/alter_content", dmessage).then(resolve => {
        console.log(resolve)
        if (resolve.data.state == 'success') {
          this.$message.success('修改成功');

        }
        if (resolve.data.state == 'failure') {

          this.$message.error(resolve.data.reason);
        }
      })


    }
  }
}
</script>

<style lang="less" >
.wordbg {
  background: url("../assets/jianchayuanbeijing.jpg");
  background-size: cover;
  width: 100%;
  height: 99vh;
}
.editbtn {
  display: flex;
  justify-content: space-between;
  width: 500px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.edit_container,
.quill-editor {
  height: 80vh;
  width: 1000px;
  margin: 0 auto;
  background-color: white;
}
.ql-editor {
  background-color: white;
}
.quill-blank {
  background-color: white;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px" !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px" !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px" !important;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5" !important;
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6" !important;
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体" !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体" !important;
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体" !important;
}
</style>
